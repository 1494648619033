
import {Component, OnInit, OnDestroy, Output, EventEmitter, Input} from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from '../../services/main.service';
import {FormGroup, Validators, FormBuilder, FormControl} from "@angular/forms";
import {AuthService} from "../../services/auth.service";
import {MatDialog} from "@angular/material";
import {MapLocationDialog} from "../../lib/map-location/map-location.dialog";


declare var $: any;


@Component({
	selector: 'add-address',
	templateUrl: 'add-address.component.html',
	styleUrls: ['add-address.component.scss'],
})
export class AddAddress implements OnInit {


	addressForm: FormGroup;
	currentPage:  any = 1;
	perPage: number;
	index:  any = 1;
	totalPages: number;

	url:any;

	@Input() Item : any;
	@Output() isAddAddress  = new EventEmitter();


	constructor(private router: Router, private mainService: MainService, protected formbuilder: FormBuilder, protected dialog: MatDialog) {

		this.addressForm = this.formbuilder.group({
			person_name: [null, [Validators.required]],
			person_phone: [null, [Validators.maxLength(11), Validators.minLength(9),Validators.required]],
			address: [null, [Validators.required]],
			latitude: [null, [Validators.required]],
			longitude: [null, [Validators.required]],
		})
	}

	ngOnInit() {
		console.log(this.Item);
		this.addressForm.patchValue(this.Item);
	}

	doAddressForm(){
		if(this.Item != ''){
			this.url = 'update-location';
			this.addressForm.addControl('id', new FormControl(this.Item.id));
		}
		else{
			this.url = 'add-location';
		}

		this.mainService.postData(this.url, this.addressForm.value).then(response => {
				if (response.status == 200 || response.status === 201) {
					this.addressForm.reset();
					this.isAddAddress.emit(false);
				}
				else {

				}
			},
			Error => {

			})

	}
	onBack(){
		this.isAddAddress.emit(false);
	}
	onSelectAddress(e){
		console.log(e);

		let split = e.latlng.split(',');
		this.addressForm.get('address').setValue(e.formatted_address);
		this.addressForm.get('latitude').setValue(split[0]);
		this.addressForm.get('longitude').setValue(split[1]);
	}
}


