
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from '../../services/main.service';
import {FormGroup, Validators, FormBuilder} from "@angular/forms";
import {AuthService} from "../../services/auth.service";
import {PaginationService} from "../../services/pagination.service";
import { OrderDetailComponent } from '../pending-orders/order_detail.component';
import { MatDialog } from '@angular/material';


declare var $: any;


@Component({
	selector: 'track-orders-list',
	templateUrl: 'track-orders-list.component.html',
	styleUrls: ['track-orders-list.component.scss'],
})
export class TrackOrdersList implements OnInit {


	Items:any = [];
	url:any = 'all-orders?status=active';
	type = 'active';

	index:  any = 1;
	totalPages: number;
	pages: any;
	totalItems: any;
	currentPage:  any = 1;
	perPage: number = 20;
	count: any;

	constructor(private router: Router, private mainService: MainService, protected formbuilder: FormBuilder, protected paginationService: PaginationService, protected dialog: MatDialog) 
	{


	}

	ngOnInit() 
	{
		this.getList(1);
	}

	getList(index)
	{
		this.url = this.url + '&page=' + index + '&per_page=' + this.perPage;
		this.mainService.getList(this.url).then(response => 
		{
			if (response.status == 200 || response.status === 201) 
			{
				console.log(response.data);
				this.Items = response.data;
				this.count = response.pagination.count;
				this.currentPage = index;
				this.pages = this.paginationService.setPagination(this.count, index, this.perPage);
				this.totalPages = this.pages.totalPages;
			}
			else
			{
				this.Items = [];
				this.count = 0;
				this.currentPage = 1;
				this.pages = this.paginationService.setPagination(this.count, index, this.perPage);
				this.totalPages = this.pages.totalPages;
			}
		})
	}

	onTrack(id:any)
	{
		this.router.navigateByUrl('main/track-orders/'+id)
	}

	setPage(pageDate: any)
	{
		this.currentPage = pageDate.page;
		this.perPage = pageDate.perPage;
		this.index = this.currentPage;
		this.getList(pageDate.page);
	}

	getLength(str: string): string
	{
		if(str == null)
		{
			return 'N/A';
		}
		if(str.length > 17)
		{
			let st = str.substring(0, 14);
			return st + '..';
		}
		else
		{
			return str;
		}
	}

	onOrderDetail(order, event): void
	{
		event.stopPropagation();
		let dialofRef = this.dialog.open(OrderDetailComponent, { autoFocus: false, disableClose: true});
		dialofRef.componentInstance.order = order;
		// dialofRef.afterClosed().subscribe(result => {
		// 	if(result)
		// 	{
		// 		this.getOrdersList(1);
		// 	}
		// })
	}
}


