
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from '../../services/main.service';
import {FormGroup, Validators, FormBuilder} from "@angular/forms";
import {AuthService} from "../../services/auth.service";
import {MatDialog, MatTabChangeEvent} from "@angular/material";
import {AlertDialog} from "../../lib/alert.dialog";


declare var $: any;


@Component({
	selector: 'contract-management',
	templateUrl: 'contract-management.component.html',
	styleUrls: ['contract-management.component.scss'],
})
export class ContractManagement implements OnInit {


	contractForm: FormGroup;
	Items:any = [];
	url:any = 'add-contract';
	addButtonText :any = 'Add';

	currentPage:  any = 1;
	perPage: number;
	index:  any = 1;
	totalPages: number;
	editItem:any = {};
	selectedIndex: number;

	subtotal = 0;
	total_discount = 0;
	total_amount = 0;

	constructor(private router: Router, private mainService: MainService, protected formbuilder: FormBuilder, protected dialog: MatDialog) {

		this.contractForm = this.formbuilder.group({
			pickup_latitude: [null, [Validators.required]],
			pickup_longitude: [null, [Validators.required]],
			pickup_address: [null, [Validators.required]],

			dropoff_latitude: [null, [Validators.required]],
			dropoff_longitude: [null, [Validators.required]],
			dropoff_address: [null, [Validators.required]],


			price: [null, [Validators.required]],
			discount: [null, [Validators.required]],
		})
	}

	ngOnInit()
	{
		this.getList('ondemand');
	}

	// doContractForm()
	// {
	// 	this.mainService.postData(this.url, this.contractForm.value).then(response =>
	// 	{
	// 		if (response.status == 200 || response.status === 201) {
	// 			this.contractForm.reset();
	// 			this.getList();
	// 			this.editItem = "";
	// 			this.url= 'add-contract';
	// 			this.addButtonText = 'Add';
	// 		}
	// 		else {
	// 			let dialogRef = this.dialog.open(AlertDialog, {autoFocus: false});
	// 			let cm = dialogRef.componentInstance;
	// 			cm.heading = 'Error';
	// 			cm.message = 'Internal Server Error';
	// 			// cm.submitButtonText = 'Yes';
	// 			cm.cancelButtonText = 'Close';
	// 			cm.type = 'error';
	// 			// cm.methodType = 'get';
	// 			// cm.methodName = 'delete-contract?id='+item.id;
	// 			// cm.showLoading = true;
	// 		}
	// 	},
	// 	Error => {

	// 	})
	// }

	getList(contract_type: string)
	{
		this.subtotal = 0;
		this.total_discount = 0;
		this.total_amount = 0;

		var url = 'all-contracts?contract_type=' + contract_type;
		this.mainService.getList(url).then(response =>
		{
			if (response.status == 200 || response.status === 201)
			{
				this.Items = response.data;
				// let subtotal = 0, total_discount = 0, total_amount = 0;

				this.Items.forEach(element => {
					this.subtotal = this.subtotal + parseFloat(element.price);
					this.total_discount = this.total_discount + parseFloat(element.discount);
					// total_amount = total_amount + parseFloat(element.total_amount);
				});

				this.total_discount = this.total_discount/this.Items.length;
				this.total_amount = this.subtotal - ((this.subtotal * this.total_discount)/100);
			}
			else
			{

			}
		})
	}

	onViewReview(){

	}

	onSelectAddress(e, type)
	{
		let split = e.latlng.split(',');
		if(type == 'pickup')
		{
			this.contractForm.get('pickup_address').setValue(e.formatted_address);
			this.contractForm.get('pickup_latitude').setValue(split[0]);
			this.contractForm.get('pickup_longitude').setValue(split[1]);
		}
		else if(type == 'dropoff')
		{
			this.contractForm.get('dropoff_address').setValue(e.formatted_address);
			this.contractForm.get('dropoff_latitude').setValue(split[0]);
			this.contractForm.get('dropoff_longitude').setValue(split[1]);
		}
	}

	onEdit(item :any)
	{
		this.contractForm.patchValue(item);
		this.editItem = item;
		console.log(this.editItem);
		this.addButtonText = 'Update';
		this.url = 'update-contract/'+item.id;
	}

	onClear()
	{
		this.addButtonText = 'Add';
		this.url = 'add-contract';
	}

	tabChanged(tabChangeEvent: MatTabChangeEvent): void
	{
		// console.log('tabChangeEvent => ', tabChangeEvent);
		// console.log('index => ', tabChangeEvent.index);
		this.selectedIndex = tabChangeEvent.index;

		if(tabChangeEvent.index == 0)
		{
			this.getList('ondemand');
		}
		else if(tabChangeEvent.index == 1)
		{
			this.getList('schedule');
		}
	}

	onDelete(item :any){

		event.stopPropagation();
		let dialogRef = this.dialog.open(AlertDialog, {autoFocus: false});
		let cm = dialogRef.componentInstance;
		cm.heading = 'Delete Contract';
		cm.message = 'Are you sure you want to delete this contract?';
		cm.submitButtonText = 'Yes';
		cm.cancelButtonText = 'No';
		cm.type = 'ask';
		cm.methodType = 'get';
		cm.methodName = 'delete-contract?id='+item.id;
		cm.showLoading = true;

		dialogRef.afterClosed().subscribe(result => {
			if(result == true){
				if(this.selectedIndex == 0)
				{
					this.getList('ondemand');
				}
				else if(this.selectedIndex == 1)
				{
					this.getList('schedule');
				}
			}
		});

		/*this.url = 'delete-contract?id='+item.id;
		this.mainService.getList(this.url).then(response => {
				if (response.status == 200 || response.status === 201) {
					this.getList();
				}
				else {

				}
			},
			Error => {

			})*/
	}
}


