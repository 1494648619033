import { Injectable } from '@angular/core';
// import { Http, Headers, Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/timeoutWith';
import { appConfig } from '../../config';
import { BaseLoaderService } from '../services/base-loader.service';

@Injectable()
export class MainService 
{
	MTCAdmin: any;
	// options: RequestOptions;
	// baseUrl: string;

	headers: HttpHeaders;
    options: any;
    public auth_key: string;
	public baseUrl: string;
	public base_url_slug: string;
	appSelectorSubscription: Subscription;
	user_app: any;
	
	// componentSettings: Subject<any> = new Subject();

	// constructor(private http: Http, private router: Router, protected loaderService: BaseLoaderService) 
	constructor(private http: HttpClient, private router: Router, protected loaderService: BaseLoaderService) 
	{
		// this.baseUrl = appConfig.base_url;
		this.MTCAdmin = JSON.parse(localStorage.getItem('MTCAdmin'));

		// var headers = new Headers();
		// headers.append('Content-Type', 'application/json');
		// headers.append('Authorization', this.UrbanpointAdmin.auth_key);
		// this.options = new RequestOptions({ headers: headers });

        this.baseUrl = appConfig.base_url;
        this.base_url_slug = appConfig.base_url_slug;

		this.headers = new HttpHeaders({ 'access-token': this.MTCAdmin.auth_key});
		// this.headers.append('Content-Type', 'multipart/form-data');
        // this.headers.append('Accept', 'application/json');
		this.options = {headers: this.headers, observe: 'response'};
		
		// this.appSelectorSubscription = this.appSelectorService.selectedApp.subscribe((response: any) =>
        // {
		// 	this.user_app = response;
		// });
	}

	public getList(url: string, isSingle?: boolean): Promise<any>
    {
		// this.user_app = this.appSelectorService.getApp();

		/*let url = '';
		if(isSingle)
		{
			url = this.baseUrl + params + '?user_app_id=' + this.user_app.user_app_id;
		}
		else
		{
			url = this.baseUrl + params + this.user_app.url;
		}
*/
        return this.http.get(this.baseUrl  + this.base_url_slug + url, this.options)
        .toPromise().then((response: any) =>
        {
            if (response.status === 401)
			{
				localStorage.clear();
				window.location.reload();
				/*localStorage.clear();
				this.router.navigate(['auth/login']);*/
			} 
			else 
			{
				return response.body;
			}
        },
        (reason: any) =>
        {
			if (reason.error.status === 401 || reason.error.status === 403)
			{
				localStorage.clear();
				window.location.reload();
				return reason;
			} 
			return reason;

        }).catch(this.handleError);
    }

	onLogout(): Promise<any>
	{
		return this.http.get(this.baseUrl + 'logout', this.options)
			.toPromise().then((response: any) =>
			{
				if (response.status === 401) 
				{
					localStorage.clear();
					this.router.navigate(['auth/login']);
				} 
				else 
				{
					return response.body;
				}
			},
			(reason: any) =>
			{
				if (reason.error.status === 401) 
				{
					localStorage.clear();
					this.router.navigate(['auth/login']);
					return reason;
				} 
				return reason;
	
			}).catch(this.handleError);
	}

	postData( apiSlug: string, formData: any): Promise<any>
	{
		// this.user_app = this.appSelectorService.getApp();
		// formData['user_app_id'] = this.user_app.user_app_id;

		// console.log(formData);

		return this.http.post(this.baseUrl + this.base_url_slug + apiSlug, formData, this.options)
			.toPromise().then((response: any) =>
			{
				if (response.status === 401) 
				{
					localStorage.clear();
					this.router.navigate(['auth/login']);
				} 
				else 
				{
					return response.body;
				}
			},
			(reason: any) =>
			{
				if (reason.error.status === 401) 
				{
					localStorage.clear();
					this.router.navigate(['auth/login']);
					return reason;
				} 
				return reason;
	
			}).catch(this.handleError);
	}

	postFormData( apiSlug: string, formData: any): Promise<any>
	{
		// this.user_app = this.appSelectorService.getApp();
		// formData['user_app_id'] = this.user_app.user_app_id;
		formData.append('user_app_id', this.user_app.user_app_id);

		console.log(formData);

		return this.http.post(this.baseUrl + apiSlug, formData, this.options)
			.toPromise().then((response: any) =>
			{
				if (response.status === 401) 
				{
					localStorage.clear();
					this.router.navigate(['auth/login']);
				} 
				else 
				{
					return response.body;
				}
			},
			(reason: any) =>
			{
				if (reason.error.status === 401) 
				{
					localStorage.clear();
					this.router.navigate(['auth/login']);
					return reason;
				} 
				return reason;
	
			}).catch(this.handleError);
	}

	public handleError(error: any): Promise<any>
    {
        return error;
    }

}
