
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { AppComponent } from './app.component';
import { LoginComponent } from './views/auth/login/login.component';
import { TemplateMainApp } from './templates/template-main-app/template-main-app.component';

import { AuthGuard, MainAuthGuard } from './guards';
import { ForgotPasswordComponent } from './views/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './views/auth/reset-password/reset-password.component';
import { AppsGuard } from './guards/apps.guard';
import { DashboardComponent } from "./views/dashboard/dashboard.component";
import { ContractManagement } from "./views/contract-management/contract-management.component";
import { ManageAddress } from "./views/address/manage-address.component";
import { AddAddress } from "./views/address/add-address.component";
import { PastOrders } from "./views/past-orders/past-orders.component";
import { NewRequest } from "./views/new-request/new-request.component";
import { TrackOrders } from "./views/track-orders/track-orders.component";
import { TrackOrdersList } from "./views/track-orders/track-orders-list.component";
import { PendingOrdersList } from './views/pending-orders/pending-orders-list.component';


const publicRoutes: Routes = [
	{ path: '', redirectTo: 'login', pathMatch: 'full' },
	{ path: 'login', component: LoginComponent },
	{ path: 'forgot-password', component: ForgotPasswordComponent },
	{ path: 'reset-password', component: ResetPasswordComponent },
	{ path: 'reset-pin', component: ResetPasswordComponent },
];

const mainApp: Routes = [
	{ path: '', redirectTo: 'dashboard', pathMatch: 'full' },
	{ path: 'dashboard', component: DashboardComponent },
	{ path: 'contract-management', component: ContractManagement },
	{ path: 'manage-address', component: ManageAddress },
	{ path: 'add-address', component: AddAddress },
	{ path: 'past-orders', component: PastOrders },
	{ path: 'pending-orders', component: PendingOrdersList },
	{ path: 'new-request/:id', component: NewRequest },
	{ path: 'pending-orders/new-request/:id', component: NewRequest },
	{ path: 'track-orders', component: TrackOrdersList },
	{ path: 'track-orders/:id', component: TrackOrders },
	// { path: 'customers/orders/:id', component: OrdersListComponent },
	// { path: 'orders', component: OrdersListComponent },
];

const appRoutes: Routes = [
	{ path: '', redirectTo: '/auth/login', pathMatch: 'full' },
	{ path: 'auth', component: AppComponent, children: publicRoutes, canActivate: [AuthGuard] },
	{ path: 'main', component: TemplateMainApp, children: mainApp, canActivate: [MainAuthGuard] },
	// { path: 'main', component: TemplateMainApp, children: mainApp },
];


@NgModule({
	imports: [RouterModule.forRoot(appRoutes)],
	exports: [RouterModule],
	providers: []
})
export class AppRoutingModule { }
