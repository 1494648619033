import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {MainService} from '../../services/main.service';
import {FormGroup, Validators, FormBuilder} from "@angular/forms";
import {AuthService} from "../../services/auth.service";
import {PaginationService} from "../../services/pagination.service";
import {AlertDialog} from "../../lib/alert.dialog";
import {MatDialog} from "@angular/material";


declare var $: any;


@Component({
    selector: 'manage-address',
    templateUrl: 'manage-address.component.html',
    styleUrls: ['manage-address.component.scss'],
})
export class ManageAddress implements OnInit {


    contractForm: FormGroup;
    Items: any = [];

    isAddAddress = false;

    editItem: any = "";
    url = '';

    index:  any = 1;
    totalPages: number;
    pages: any;
    totalItems: any;
    currentPage:  any = 1;
    perPage: number = 20;
    count: any;


    constructor(private mainService: MainService, protected paginationService: PaginationService, protected dialog: MatDialog) {

    }


    ngOnInit() {
        this.getList(1);
    }

    onAddAddress() {
        // this.router.navigateByUrl('/main/add-address')
        this.editItem = "";
        this.isAddAddress = true;
    }

    onChange(e: any) {
        this.isAddAddress = e;
        this.getList(1);
    }

    getList(index) {
        this.url = 'view-location?page=' + index + '&per_page=' + this.perPage;
        this.mainService.getList(this.url).then(response => {
                if (response.status == 200 || response.status === 201) {
                    this.Items = response.data;
                    /*this.count = response.pagination.count;
                    this.currentPage = index;
                    this.pages = this.paginationService.setPagination(this.count, index, this.perPage);
                    this.totalPages = this.pages.totalPages;*/
                }
                else
                {
                    this.Items = [];
                   /* this.count = 0;
                    this.currentPage = 1;
                    this.pages = this.paginationService.setPagination(this.count, index, this.perPage);
                    this.totalPages = this.pages.totalPages;*/
                }
            },
            Error => {

            })
    }

    onEdit(item: any) {
        this.editItem = item;
        this.isAddAddress = true;
    }

    onDelete(item: any) {

        event.stopPropagation();
        let dialogRef = this.dialog.open(AlertDialog, {autoFocus: false});
        let cm = dialogRef.componentInstance;
        cm.heading = 'Delete Address';
        cm.message = 'Are you sure you want to delete this Address?';
        cm.submitButtonText = 'Yes';
        cm.cancelButtonText = 'No';
        cm.type = 'ask';
        cm.methodType = 'get';
        cm.methodName =  'delete-location?id=' + item.id;
        cm.showLoading = true;

        dialogRef.afterClosed().subscribe(result => {
            if(result == true){
                this.getList(1);
            }
        });

        /*this.url = 'delete-location?id=' + item.id;
        this.mainService.getList(this.url).then(response => {
                if (response.status == 200 || response.status === 201) {
                    this.getList(1);
                }
                else {

                }
            },
            Error => {

            })*/
    }

    setPage(pageDate: any) {
        this.currentPage = pageDate.page;
        this.perPage = pageDate.perPage;
        this.index = this.currentPage;
        this.getList(pageDate.page);
    }
}


