
import { Component, OnInit } from '@angular/core';
import { MainService } from '../../services/main.service';
import { PaginationService } from "../../services/pagination.service";
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { OrderDetailComponent } from './order_detail.component';


@Component({
	selector: 'pending-orders-list',
	templateUrl: 'pending-orders-list.component.html'
})
export class PendingOrdersList implements OnInit
{
	Items: any = [];

	index: any = 1;
	totalPages: number;
	pages: any;
	totalItems: any;
	currentPage: any = 1;
	perPage: number = 20;
	count: any;

	constructor(private mainService: MainService, protected paginationService: PaginationService, protected router: Router, protected dialog: MatDialog)
	{
	}

	ngOnInit()
	{
		this.getList(1);
	}

	getList(index)
	{
		let url: any = 'all-orders?status=pending' + '&page=' + index + '&per_page=' + this.perPage;

		this.mainService.getList(url).then(response =>
		{
			if (response.status == 200 || response.status === 201)
			{
				this.Items = response.data;
				this.count = response.pagination.count;
				this.currentPage = index;
				this.pages = this.paginationService.setPagination(this.count, index, this.perPage);
				this.totalPages = this.pages.totalPages;
			}
			else
			{
				this.Items = [];
				this.count = 0;
				this.currentPage = 1;
				this.pages = this.paginationService.setPagination(this.count, index, this.perPage);
				this.totalPages = this.pages.totalPages;
			}
		});
	}

	onEditOrder(order, event)
	{
		event.stopPropagation();
		this.router.navigateByUrl('/main/pending-orders/new-request/' + order.id);
	}

	setPage(pageDate: any)
	{
		this.currentPage = pageDate.page;
		this.perPage = pageDate.perPage;
		this.index = this.currentPage;
		this.getList(pageDate.page);
	}

	getLength(str: string): string
	{
		if(str == null)
		{
			return 'N/A';
		}
		if(str.length > 17)
		{
			let st = str.substring(0, 14);
			return st + '..';
		}
		else
		{
			return str;
		}
	}

	onOrderDetail(order, event): void
	{
		event.stopPropagation();
		let dialofRef = this.dialog.open(OrderDetailComponent, { autoFocus: false, disableClose: true});
		dialofRef.componentInstance.order = order;
		// dialofRef.afterClosed().subscribe(result => {
		// 	if(result)
		// 	{
		// 		this.getOrdersList(1);
		// 	}
		// })
	}
}


